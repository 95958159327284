<template>
    <div class="Awarp">
        <dashCard class="powerBox23" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">事件统计</template>
            <template slot="aside"></template>
            <div class="card-content1">
                <chartBoard ref="chart1" :option="chartOption1"/>
            </div>
            <div class="card-content2">
                <div class="subTable">
                    <div class="subTable-list" v-for="(item,idx) in detailInfo.chart1.seriesData" :key="idx">
                        <span><i :style="{background: colorList[idx]}"> </i>{{ item.name }}</span>
                        <span>{{ item.value || 0 }} 个</span>
                        <span>{{ item.percent || 0 }} %</span>
                    </div>
                </div>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <powerBox23  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'powerBox23',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中
                searchObj: {},
                chartOption1: {},
                detailInfo: {
                    chart1: {}
                },
                colorList: ["#DBDBDB", "#3266FF", "#FF7300","#F02F40","#D21423"]
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

         //每月报警预警数
         getData() {
            this.isLoading = true
            this.detailInfo = {}
            var chart1 = {
                seriesData: [
                    {
                        name: "常规",
                        value: 636,
                        percent: 50
                    },
                    {
                        name: "维护",
                        value: 318,
                        percent: 25
                    },
                    {
                        name: "预警",
                        value: 159,
                        percent: 12.5
                    },
                    {
                        name: "告警",
                        value: 59,
                        percent: 2.5
                    },
                    {
                        name: "事故",
                        value: 100,
                        percent: 10
                    },
                ]
             }

            this.detailInfo.chart1 = chart1
            this.initEchart()
            this.isLoading = false
        },
		initEchart() {     
            var {seriesData} = this.detailInfo.chart1
            var sum = seriesData.reduce(function(prev, cur) {
                        return prev + cur.value;
                    }, 0)
            sum = this.toThousandsSeparator(sum)
            var colorList = this.colorList
            var option ={
                animation:true,
                layoutAnimation:false,
                title: [
                    {
                        text: "总计(项)",
                        subtext: sum,
                        textStyle: {
                            fontSize: 12,
                            color: "#1D2129",
                            fontFamily: "ABBvoice_WCNSG_Rg"
                        },
                        subtextStyle: {
                            fontSize: 24,
                            color: "#1D2129",
                            fontWeight: '700',
                            fontFamily: "ABBvoice_WCNSG_Rg"
                        },
                        textAlign: "center",
                        left: '50%',
                        top: '35%'
                    },
                    {
                        subtext: "事件",
                        subtextStyle: {
                            fontSize: 12,
                            color: "#868686",
                            fontFamily: "ABBvoice_WCNSG_Rg"
                        },
                        textAlign: "center",
                        left: '50%',
                        top: '55%'
                    },
                ],
                tooltip: {
                        trigger: 'item'
                },
                series: [{
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                return colorList[params.dataIndex]
                            },
                            borderColor: '#172659',
                            borderWidth: 0
                        }
                    },
                    type: 'pie',
                    radius: ['60%', '75%'],
                    center: ["50%", "50%"],
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: '#34569D'
                            }
                        }
                    },
                    label: {
                        normal: {
                            show: false,
                            formatter: params => {
                                return (
                                    '{name|' + params.name + '}{percent|' + params.percent.toFixed(0) + '%}'
                                );
                            },
                            padding: [0, -5, 0, -5],
                        }
                    },
                    data: seriesData
                }
                ]
            }
            this.updateChart('chart1', 'chartOption1', option)
        },
        updateChart: function (refName, optionName, option = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option
            setTimeout(() => {
                this.$refs[refName].updateChartView()
            }, 500)
        },
            
        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.powerBox23 {
    .card-content1 {
        flex: 1;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
    }
    .card-content2 {
        margin-top: 10px;
        height: 220px;
        width: 100%;
        .subTable {
            height: 100px;
            display: flex;
            flex-direction: column;
            .subTable-list {

            height: 44px;
            line-height: 44px;
            width: 100%;
            box-shadow: 0px -1px 0px 0px #ebebeb inset; 

            display: flex;
            justify-content: space-between;
            align-content: center;
            font-size: 14px;
            font-weight: 600;


            &:last-child {
            box-shadow: none;
            }
            span {
            display: flex;
            align-items: center;
            }
            i {
            width: 4px;
            height: 36px;
            margin-right: 6px;
            display: inline-block;
            }
            }
        }
    }
}
</style>